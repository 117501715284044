const fetchGraphQL = async (query, preview = false) => {
  try {
    const response = await fetch(
      `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CF_SPACE_ID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            preview
              ? process.env.NEXT_PUBLIC_CF_PREVIEW_ACCESS_TOKEN
              : process.env.NEXT_PUBLIC_CF_DELIVERY_ACCESS_TOKEN
          }`,
        },
        body: JSON.stringify({ query }),
      }
    )

    return response.json()
  } catch (error) {
    return error
  }
}

module.exports = fetchGraphQL
