import { WebsiteNavItemComponent, ModalComponent } from "./fragments"
import fetchGraphQL from "./fetchGraphQL"

const getWebsiteNavItems = async (locale, countryTag, limit, preview) =>
  // eslint-disable-next-line no-return-await
  await fetchGraphQL(
    `
    query WebsiteNavItems {
      main: websiteNavItemCollection(
        limit:${limit}, 
        order:[sortOrder_ASC], 
        preview:${preview}, 
        locale:"${locale}",
        where:{
          AND:[
            {
              contentfulMetadata: {
                tags:{
                  id_contains_some:["${countryTag}"]
                }
              },
              linkType_contains_all:["Main"]
            }
          ]
        }) {
          total
          items 
            ${WebsiteNavItemComponent}
      }
      headerButtons: websiteNavItemCollection(
        limit:${limit}, 
        order:[sortOrder_ASC], 
        preview:${preview}, 
        locale:"${locale}",
        where:{
          AND:[
            {
              contentfulMetadata: {
                tags:{
                  id_contains_some:["${countryTag}"]
                }
              },
              linkType_contains_all:["Button"],
              linkType_contains_none:["Footer"],
            }
          ]
        }) {
          total
          items 
            ${WebsiteNavItemComponent}
      }
      footerButtons: websiteNavItemCollection(
        limit:${limit}, 
        order:[sortOrder_ASC], 
        preview:${preview}, 
        locale:"${locale}",
        where:{
          AND:[
            {
              contentfulMetadata: {
                tags:{
                  id_contains_some:["${countryTag}"]
                }
              },
              linkType_contains_all:["Button", "Footer"]
            }
          ]
        }) {
          total
          items 
            ${WebsiteNavItemComponent}
      }
      footerLinks: websiteNavItemCollection(
        limit:${limit}, 
        order:[sortOrder_ASC], 
        preview:${preview}, 
        locale:"${locale}",
        where:{
          AND:[
            {
              contentfulMetadata: {
                tags:{
                  id_contains_some:["${countryTag}"]
                }
              },
              linkType_contains_some:["Footer"]
              linkType_contains_none:["Button"],
            }
          ]
        }) {
          total
          items 
            ${WebsiteNavItemComponent}
      }
      socialLinks : websiteNavItemCollection(
        limit:${limit}, 
        order:[sortOrder_ASC], 
        preview:${preview}, 
        locale:"${locale}",
        where:{
          AND:[
            {
              contentfulMetadata: {
                tags:{
                  id_contains_some:["${countryTag}"]
                }
              },
              linkType_contains_all:["Social"]
            }
          ]
        }) {
          total
          items ${WebsiteNavItemComponent}
      }
      popup : popupCollection(
        limit:${limit}, 
        preview:${preview}, 
        locale:"${locale}",
        where:{
          AND:[
            {
              contentfulMetadata: {
                tags:{
                  id_contains_some:["${countryTag}"]
                }
              }
            }
          ]
        }) {
          total
          items ${ModalComponent}
      }
    }
  `,
    preview
  )

export default getWebsiteNavItems
