/* eslint-disable func-names */
/* eslint-disable react/no-danger */
/* eslint-disable react/self-closing-comp */
import App from "next/app"
import React, { useEffect} from "react"
import { uid } from "uid"
import dynamic from "next/dynamic"
import Script from "next/script"
import { useRouter } from "next/router"
import Cookies from 'js-cookie';

// Instantiates a client
// const recaptchaenterpriseClient = new RecaptchaEnterpriseServiceClient();
import { GlobalContextProvider } from "../src/context/layout-context"

import "../public/css/iframe-punchh.css" 
import "../src/css/tailwind.css"

import getWebsiteNavItems from "../graphql/website-nav-items"

import { GTM_ID, pageview,pageViewEvent,PromotionView,Click } from "../utils/gtm"

const Header = dynamic(() => import("../src/components/layout/header"), {
  ssr: true,
})
const Footer = dynamic(() => import("../src/components/layout/footer"))
const ModalComponent = dynamic(() => import("../src/components/modals/modal-component/modal-component"))
const VideoInModalComponent = dynamic(() => import("../src/components/video/video-in-modal-component"))

function MyApp({
  Component,
  pageProps,
  main,
  headerButtons,
  footerButtons,
  socialLinks,
  footerLinks,
  popup,
}) {
  const router = useRouter()
  const pageTitle= pageProps && pageProps.page && pageProps.page.items && pageProps.page.items[0] && pageProps.page.items[0].pageTitle || pageProps && pageProps.page && pageProps.page.items && pageProps.page.items[0] && pageProps.page.items[0].title || '';
  const pageName = pageProps && pageProps.page && pageProps.page.items && pageProps.page.items[0] && pageProps.page.items[0].slug || '';
  const pageIndexId = pageProps && pageProps.page && pageProps.page.items && pageProps.page.items[0] && pageProps.page.items[0].sys && pageProps.page.items[0].sys.id || ''
  let lang;
  let pageReferrer;
  let pageLocation;
  if(typeof window !== 'undefined'){
    lang = window.document.documentElement.lang
    pageReferrer = window.document.referrer
    pageLocation = window.location.href
  }

  function PromotionFunctionView(){
    const arr = [];
    const promotions = document.querySelectorAll("[data-layer-event=view_promotion]");
    if(promotions && Array.from(promotions).length > 0){
      Array.from(promotions).forEach((promotion,index) => {
        if(!arr.includes(promotion.getAttribute('data-layer-id')) && promotion.getAttribute('data-layer-id')){
          arr.push(promotion.getAttribute('data-layer-id'));
          const items = [{item_id: promotion.getAttribute('data-layer-id'), item_name: promotion.getAttribute('data-layer-name')}];
          PromotionView(items,index);
        }
      });
    }
  }
  function checkDomain(url){
    let link = url;
    if ( link.indexOf('//') === 0 ) { link = window.location.protocol + link; }
    return link.toLowerCase().replace(/([a-z])?:\/\//,'$1').split('/')[0];
  }

  function isExternal(url) {
    return ( ( url.indexOf(':') > -1 || url.indexOf('//') > -1 ) && checkDomain(window.location.href) !== checkDomain(url) );
  }

  function Domain(url){
    let domain = (new URL(url));
    domain = domain.hostname.replace('www.','');
    return domain;
  }

  function getParents(elemTest) {

    let elem = elemTest;
    let count  = 0;

    while(elem.parentNode && elem.parentNode.nodeName.toLowerCase() !== 'body' && elem.parentNode.nodeName.toLowerCase() !== '#document' && count < 15) {
      elem = elem.parentNode;

      if(elem && elem.tagName === "BUTTON" || elem.role === 'button' || elem.getAttribute('data-layer-component') === 'gallery-item' || elem.tagName === "A" || elem.classList.contains('pagination')){
        return elem;
      }
      count +=  1;
    }
    return null;
  }

  useEffect( () => {
      document.addEventListener("click", e => {
          // const buttons = document.getElementsByTagName('button');
          // console.log(buttons);
          let logoIdAdd = null;
          let logotextAdd = null;
          const parrent = getParents(e.target);
          const el = e.target;
          let listenClick = false;
          let text = null;
          let linkDomainText = null;
          let hrefParent = null;
          let parentProtocol = null;
          let parentId = null;

          if(el.classList.contains('logo-click-listener')){
            logoIdAdd = "logo-header";
            logotextAdd = "Logo Header";
          } else if (el.classList.contains('hamburger-line')){
            logoIdAdd = "hamburger-menu";
            logotextAdd = "Hamburger Menu";
          }
          if(el.tagName !== "A" &&  e.target.role !== 'button' && el.tagName !== 'BUTTON'){

            // Array.from(buttons).forEach(btn => {
            //   if(parrent && parrent.isSameNode(btn)){
            //     listenClick = true;
            //     text = parrent.textContent;
            //   }
            // });
            if(parrent && parrent.classList.contains('logo-click-listener')){
              logoIdAdd  = "logo-header";
              logotextAdd = "Logo Header";
            } else if (parrent && parrent.classList.contains('hamburger-line')){
              logoIdAdd = "hamburger-menu";
              logotextAdd = "Hamburger Menu";
            }

            if(parrent && parrent.tagName === "BUTTON"){
              listenClick = true;
              text = parrent.textContent;
            } else if(parrent && parrent.role === 'button'){
              listenClick = true;
              text = parrent.textContent;
            } else if(parrent && parrent.parentNode.role === 'button'){
              listenClick = true;
              text = parrent.textContent;
            } else if(parrent && parrent.parentNode.tagName === "A" || parrent && parrent.tagName === "A"){
              listenClick = true;
              text = parrent.textContent;
              parentProtocol = parrent.protocol ? parrent.protocol : null;
              hrefParent = parrent.href ? parrent.href : null;
              linkDomainText = parrent.href ? Domain(parrent.href) : null;
              parentId = parrent.id ? parrent.id : null;
            } else if(parrent && parrent.parentNode.classList.contains('pagination') || parrent && parrent.classList.contains('pagination')){
              listenClick = true;
              text = parrent.textContent;
            } else if(el.textContent === 'Load More'){
              listenClick = true;
              text = 'Load More';
            }


            text = el.alt ? el.alt : text;

          }

          if(el.tagName === "A" || e.target.role === 'button' || el.tagName === 'BUTTON' || listenClick || parrent && parrent.getAttribute('data-layer-component') === 'gallery-item' || el.getAttribute('data-layer-component') === 'gallery-item'){
             const outbound = el.href ? isExternal(el.href) : null;
             const className = el.attributes && el.attributes.class && el.attributes.class.nodeValue ? el.attributes.class.nodeValue : null;
             const linkText = el.innerText || logotextAdd || text;
             const href = el.href ? el.href : hrefParent;
             const protocol = el.protocol ? el.protocol : parentProtocol;
             const linkDomain = el.href ? Domain(el.href) : linkDomainText;
             const linkId = el.id || parentId || linkText ? linkText.toLowerCase().replaceAll(' ','-').replaceAll('/','').replace('--','-').replaceAll(/[^a-z0-9-]/gi,'') : null || logoIdAdd || null;
             if(linkText || linkId  ||href){
                Click(className,linkText,href,protocol,outbound,linkDomain,linkId);
             }
          }
       });
    },[isExternal]
  );

  useEffect(() => {
    const [,firstPath] = window.location.pathname.split("/");
    const [,,secondPath] = window.location.pathname.split("/");
    const test = {
      language: lang || '',
      pageCategory:  firstPath || 'home',
      pageCategory2: '',
      pageCategory3: '',
      pageCategory4: '',
      pageCategory5: '',
      pageId: pageIndexId,
      pageLocation: pageLocation || '',
      pageName: pageName  || '',
      pageReferrer:  pageReferrer || '' ,
      pageTitle: pageTitle || '',
      pageType: firstPath || '',
      siteBrand: "nando's",
      siteCountry: process.env.NEXT_PUBLIC_COUNTRY || '',
      siteRegion: 'NA',
      siteSection: secondPath || firstPath || 'home',
      siteSection2: '',
      siteSection3: '',
      siteSection4: '',
      siteSection5: '',
      userId: Cookies.get('punchh_authenticated_user') ? JSON.parse(Cookies.get('punchh_authenticated_user')).user_id : null,
      userLoginState: Cookies.get('punchh_authenticated_user') ? 'true' : 'false',
    };

    pageViewEvent(test);
    setTimeout(()=> {   PromotionFunctionView();
    },1000);

    router.events.on("routeChangeComplete", pageview)
    return () => {
      router.events.off("routeChangeComplete", pageview)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events, pageProps]);

  return (
    <>
      {/* Google Tag Manager - Global base code */}
      {GTM_ID && (
        <Script
          id="gtm-script"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
          }}
        />
      )}
      {/* //END Google Tag Manager - Global base code */}
      {router && router.pathname !== "/oat-ft" ? (
        <div>
          {/* <GoogleReCaptchaProvider
            reCaptchaKey={SITE_KEY}
            scriptProps={{
              async: false,
              defer: false,
              appendTo: "head",
            }}
            useEnterprise
          > */}
            <GlobalContextProvider>
              <>
                {router && router.pathname === "/oat-ft" ? (null) : (
                  <Header main={main} headerButtons={headerButtons} pageProps={pageProps} />
                )}
                <main data-layer-region="body">
                  <Component {...pageProps} />
                </main>
                {router && router.pathname === "/oat-ft" ? (null) : (
                  <Footer
                    footerButtons={footerButtons}
                    footerLinks={footerLinks}
                    socialLinks={socialLinks}
                  />
                )}
                <VideoInModalComponent />
                {popup &&
                  popup.items.length > 0 &&
                    popup.items.map(item => (
                      <ModalComponent popup={item} key={uid(16)} slug="/" />
                    ))}
              </>
            </GlobalContextProvider>
          {/* </GoogleReCaptchaProvider> */}
        </div>
      ) : (
        <main data-layer-region="body">
          <Component {...pageProps} />
        </main>
      ) }
    </>
  )
}

MyApp.getInitialProps = async appContext => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext)

  const websiteNavItems =
    (await getWebsiteNavItems(
      `en-${process.env.NEXT_PUBLIC_COUNTRY.toUpperCase()}`,
      `${process.env.NEXT_PUBLIC_COUNTRY}`,
      20,
      false
    )) ?? []

  return {
    ...appProps,
    ...websiteNavItems.data,
  }
}

// Web Vitals Performance with Google Analytics
export function reportWebVitals({ id, name, label, value }) {
  if (window && window.gtag) {
    window.gtag('event', name, {
      event_category:
      label === 'web-vital' ? "Web Vitals" : "Next.js custom metric",
      value: Math.round(name === "CLS" ? value * 1000 : value),
      event_label: id,
      non_interaction: true,
    })
  }
}

export default MyApp