import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useEffect,
} from "react";

export const GlobalContext = createContext();

const reducer = (state, pair) => ({ ...state, ...pair });

const initialState = {
  locations: [],
  selectedRestaurant: null,
  isMobile: null,
  isTablet: null,
  isDesktop: null,
  headerHeight: null,
  mapHeight: null,
  isModalOpen: false,
  selectedCategory: null,
  recipeCategory: [],
  selectedProduct: null,
  filterLocationsBy: [],
  locationToSearch: null,
  filteredRestaurants: [],
  setCenter: null,
  setLng: null,
  setLat: null,
  videoId: null,
  videoSrc: null,
  modalVideoOpen: false,
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobalContext must be used within a GlobalContext Provider");
  }
  return context;
};

export function GlobalContextProvider({ children }) {
  const [globalState, updateGlobalState] = useReducer(reducer, initialState);

  const value = useMemo(
    () => ({
      globalState,
      updateGlobalState,
    }),
    [globalState]
  );

  // Check window size and validate if it's mobile, tablet, or desktop
  const validateDevice = () => {
    const { innerWidth: width } = window;
    if (width >= 1024 && !globalState.isDesktop) {
      updateGlobalState({
        isMobile: false,
        isTablet: false,
        isDesktop: true,
      });
    } else if (width >= 768 && width < 1024 && !globalState.isTablet) {
      updateGlobalState({
        isMobile: false,
        isTablet: true,
        isDesktop: false,
      });
    } else if (width < 768 && !globalState.isMobile) {
      updateGlobalState({
        isMobile: true,
        isTablet: false,
        isDesktop: false,
      });
    }
  };

  useEffect(() => {
    // Initial check
    validateDevice();

    const resizeListener = () => {
      validateDevice();
    };

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [globalState.isMobile, globalState.isTablet, globalState.isDesktop]);

  return (
    <GlobalContext.Provider value={value}>
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalContextProvider;
