export const CFAsset = `
    {
        title
        description
        fileName
        size
        url
        width
        height
        contentType
    }
`

export const CoverComponent = `
    {
        image
            ${CFAsset}
        mobileImage
            ${CFAsset}
        captionAlt
    }
`

export const CTAComponent = `
    {
        label
        externalUrl
        scrollId
        icon
          ${CFAsset}
        type
        openInNewTab
        buttonColor
        trackingId
    }
`

export const CTACollection = `
    ctaCollection(limit: 5) {
      total
      items 
        ${CTAComponent}
    }
`

export const SEOComponent = `
    {
        seoTitle
        description
        keywords
        noindex
        nofollow
        redirectUrl
        image
            ${CFAsset}
    }
`

export const PromotionComponent = `
    {
      name
      description {
        json
      }
      multipleCtaCollection(limit:2) {
        total
        items
          ${CTAComponent}
      }
      cta
        ${CTAComponent}
      cover
        ${CoverComponent}
      backgroundSize
      coverPosition
      promoId
      videoId
      source
      loopVideoId
      loopVideoSource
      loopVideoFile {
        url
      }
      popupPlayer
      addTriangle
      blockTriangle
      backgroundColor
    }
`

export const PromotionHTMLFormComponent = `
    {
      backgroundColor
      name
      description {
        json
      }
      multipleCtaCollection(limit:2) {
        total
        items
          ${CTAComponent}
      }
      cover
        ${CoverComponent}
      coverPosition
      backgroundSize
      formHtml
      iframeSource
      formHeight
      promoId
      videoId
      source
      loopVideoId
      loopVideoSource
      loopVideoFile {
        url
      }
      popupPlayer
    }
`

export const ItemsCollection = `
    itemsCollection(limit: 20) {
        total
        items{
            ... on ListItem{
              name
              displayName
              subtitle
              description {
                  json
              }
              imageIcon
                  ${CFAsset}
              imageUrl
              cta
                  ${CTAComponent}
              addCtaToName
              videoId
              source
              loopVideoId
              loopVideoSource
              loopVideoFile {
                url
              }
              popupPlayer
            }
        }
    }
`

export const ListComponent = `
     {
        header
        intro {
          json
        }
        textAlign
        image
          ${CFAsset}
        backgroundColor
        ${ItemsCollection}
        cta:
          ${CTACollection}
        videoId
        source
        loopVideoId
        loopVideoSource
        loopVideoFile {
          url
        }
        popupPlayer
        locationsList
        componentId
    }
`

export const ModalComponent = `
    {
      backgroundColor
      cover
        ${CFAsset}
      description {
        json
      }
      contentColor
      title
      titleSize
      seconds
      cookieName
      contentAlign
      contentSize
      titleDecoration
      buttonsCollection(limit:2) {
        total
        items {
          label
          externalUrl
          scrollId
          type
          openInNewTab
          buttonColor
          trackingId
        }
      }
    }
`

export const HeroComponent = `
     {
        backgroundColor
        header
        subtitle
        content {
            json
        }
        contentPosition
        contentNarrow
        cover
            ${CoverComponent}
        enableOverlay
        buttonsCollection(limit:5) {
            total
            items
                ${CTAComponent}
        }
        videoId
        source
        loopVideoId
        loopVideoSource
        loopVideoFile {
          url
        }
        popupPlayer
    }
`

export const HeroVideoComponent = `
      {
        backgroundColor
        header
        subtitle
        content {
            json
        }
        buttons: buttonsCollection(limit: 5) {
            total
            items
                ${CTAComponent}
        }
        videoId
        source
        loopVideoId
        loopVideoSource
        loopVideoFile {
          url
        }
        popupPlayer
        thumbnail: videoThumbnail
          ${CoverComponent}
    }
`

export const HeroTextTwoColumnBlock = `
    {
      backgroundColor
      blockName
      columnOneHeader
      columnOneIntro {
        json
      }
      columnTwoHeader
      columnTwoIntro {
        json
      }
      buttonsCollection(limit: 5) {
          total
          items
              ${CTAComponent}
      }
    }
`

export const BannerComponent = `
    {
        backgroundColor
        header
        cover
            ${CoverComponent}
        body{
            json
        }
        ctaCollection (limit:5) {
            limit
            items
                ${CTAComponent}
        }
        videoId
        source
        loopVideoId
        loopVideoSource
        loopVideoFile {
          url
        }
        popupPlayer
    }
`

export const WebsiteNavItemComponent = `
    {
      name
      slugUrl
      linkType
      iconType
      sortOrder
      itemsCollection {
        total
        items {
          name
          slugUrl
          sortOrder
        }
      }
    }
`

export const RestaurantComponent = `
  {
      id: oloId
      name
      slug
      status
      telephone
      email
      doordashUrl
      uberEatsUrl
      caviarUrl
      skipTheDishesUrl
      grubhubUrl
      nandosDirect
      workUrl
      orderOnlineUrl
      orderAtTableUrl
      amenities
      dineInCapacity
      streetAddress
      city
      state
      latitude
      longitude
      zipCode
      hours
  }
`

export const BannerBlock = `
  {
    blockName
    header
    body {
      json
    }
    backgroundColor
    backgroundImage
      ${CFAsset}
    cta:
      ${CTACollection}
    videoId
    source
    loopVideoId
    loopVideoSource
    loopVideoFile {
      url
    }
    popupPlayer
  }
`

export const CoverBlock = `
  {
    image{
      url
    }
    mobileImage{
      url
    }
    captionAlt
  }
`

export const ImageTextBlock = `
  {
      blockName
      header
      content {
        json
      }
      extraDescription {
        json
      }
      alignContent
      cover
        ${CFAsset}
      mobileCover
        ${CFAsset}
      coverPosition
      backgroundSize
      cta:
        ${CTACollection}
      backgroundColor
      promoId
      videoId
      source
      loopVideoId
      loopVideoSource
      loopVideoFile {
        url
      }
      popupPlayer
  }
`

export const ImageTextBlockTwoBlocks = `
  {
    blockName
    blocks: contentCollection(limit: 2) {
      total
      items {
        ... ${ImageTextBlock}
      }
    }
  }
`

export const TextCtaBlocks = `
  {
    blockName
    header
    intro {
      json
    }
    type: blockType
    cta:
      ${CTACollection}
    backgroundColor
    componentId
  }
`

export const GridComponentBlock = `
  {
    blockName
    backgroundColor
    items:
      ${ItemsCollection}
  }
`

export const TextImagesGridBlock = `
  {
    blockName
    backgroundColor
    header
    intro {
      json
    }
    cta
      ${CTAComponent}
    images: imagesCollection(limit: 5) {
       total
       items
        ${CFAsset}
    }
    recipes:recipesCollection (limit: 9) {
      total
      items {
        title
        slug
        image
          ${CFAsset}
      }
    }
  }
`

export const FullVideoBlock = `
  {
    blockName
    thumbnail: videoThumbnail
      ${CFAsset}
    videoId
    source
    loopVideoId
    loopVideoSource
    loopVideoFile {
      url
    }
    videoWidth
    popupPlayer
    fullVideo
  }
`

export const LocationBlock = `
  {
    blockName
    header
    intro {
      json
    }
    cta
      ${CTAComponent}
    locations: locationsCollection(limit: 10){
      total
      restaurants: items
        ${RestaurantComponent}
    }
    map {
      lat
      lon
    }
    location {
      lat
      lon
    }
    mapZoom
    backgroundColor
  }
`

export const RestaurantsCardsBlock = `
  {
    blockName
    header
    intro {
      json
    }
    restaurants: restaurantsCollection(limit: 3){
      total
      items
        ${RestaurantComponent}
    }
  }
`

export const FormBlock = `
  {
    blockName
    backgroundColor
    header
    getCouponsFromPunchh
    couponCodeCanada
    dynamicGenerationUrlPath
    campaignToken
    intro {
      json
    }
    formFields
    image
      ${CFAsset}
    cta:
      ${CTACollection}
  }
`

export const MenuInfoCategoryBlock = `
  {
    blockName
    header
    description {
      json
    }
    multipleCtaCollection(limit:2) {
      total
      items
        ${CTAComponent}
    }
    menuCategory {
      name
      dishes:itemsCollection (limit:10) {
        total
        items {
          id: chainproductid
          name
          description
          menuImage
          popUpImage
          type
          newItem
          ingredients
          allergens
          cost
          nutritionalInformation {
              id
              calories
              caloriesAdditionalInformation
              totalFat
              totalFatAdditionalInformation
              saturatedFat
              saturatedFatAdditionalInformation
              transFatAdditionalInformation
              transFat
              transFatAdditionalInformation
              cholesterol
              cholesterolAdditionalInformation
              sodium
              sodiumAdditionalInformation
              totalCarbs
              totalCarbsAdditionalInformation
              dietaryFiber
              dietaryFiberAdditionalInformation
              sugar
              sugarAdditionalInformation
              protein
              proteinAdditionalInformation
          }
          options: optionsCollection (limit:3){
            total
            items{
                id: oloid
                name
                cost
                sortOrder
            }
          }
        }
      }
    }
  }
`

export const PromoCard = `
  {
    title
    slug
    datesDescription
    contentfulMetadata {
      tags {
        id
      }
    }
    summary
    locationsField {
      json
    }
    promoDays
    thumbnail
      ${CFAsset}
  }
`

export const SimplePromoCard = `
  {
    name
    datesDescription
    contentfulMetadata {
      tags {
        id
      }
    }
    summary
    locationsField {
      json
    }
    promoDays
    thumbnail
      ${CFAsset}
  }
`

export const ContentBlocksCollection = `
  contentBlocksCollection(limit: 20){
    total
    items {
      blockType: __typename
      ... on Hero
            ${HeroComponent}
      ... on HeroVideo
            ${HeroVideoComponent}
      ... on HeroTextTwoColumn
            ${HeroTextTwoColumnBlock}
      ... on ImageTextBlock
            ${ImageTextBlock}
      ... on ImageTextBlockTwoBlocks
            ${ImageTextBlockTwoBlocks}
      ... on Promotions
            ${PromotionComponent}
      ... on MenuInfoCategoryBlock
            ${MenuInfoCategoryBlock}
      ... on Cta
            ${CTAComponent}
      ... on List
            ${ListComponent}
      ... on BannerBlock
            ${BannerBlock}
      ... on TextCta
            ${TextCtaBlocks}
      ... on Cover
            ${CoverBlock}
      ... on TextImagesGrid {
          blockName
          backgroundColor
          header
          imagesCollection {
            items {
              title
              description
              fileName
              size
              url
              width
              height
            }
          }
        }
      ... on GridComponent {
        blockName
        ${ItemsCollection}
      }
      ... on HeroVideo {
        backgroundColor
          header
          subtitle
          coverPosition
          content {
              json
          }
          videoId
          source
          loopVideoId
          loopVideoSource
          loopVideoFile {
            url
          }
          popupPlayer
          thumbnail: videoThumbnail
            ${CoverComponent}
      }
      ... on FormBlock
        ${FormBlock}
    }
  }
`

export const CateringBlocksCollection = `
  contentBlocksCollection(limit: 50){
    total
    items {
      blockType: __typename
      ... on Hero
            ${HeroComponent}
      ... on HeroVideo
            ${HeroVideoComponent}
      ... on HeroTextTwoColumn
            ${HeroTextTwoColumnBlock}
      ... on ImageTextBlock
            ${ImageTextBlock}
      ... on ImageTextBlockTwoBlocks
            ${ImageTextBlockTwoBlocks}
      ... on Promotions
            ${PromotionComponent}
      ... on MenuInfoCategoryBlock
            ${MenuInfoCategoryBlock}
      ... on Cta
            ${CTAComponent}
      ... on List
            ${ListComponent}
      ... on BannerBlock
            ${BannerBlock}
      ... on TextCta
            ${TextCtaBlocks}
      ... on Cover
            ${CoverBlock}
      ... on TextImagesGrid {
          blockName
          backgroundColor
          header
          imagesCollection {
            items {
              title
              description
              fileName
              size
              url
              width
              height
            }
          }
        }
      ... on GridComponent
        ${GridComponentBlock}
      ... on HeroVideo {
        backgroundColor
          header
          subtitle
          coverPosition
          content {
              json
          }
          videoId
          source
          loopVideoId
          loopVideoSource
          loopVideoFile {
            url
          }
          popupPlayer
          thumbnail: videoThumbnail
            ${CoverComponent}
      }
      ... on FormBlock
        ${FormBlock}
    }
  }
`

export const LocationsBlocks = `
  contentBlocksCollection(limit: 50){
    total
    items {
      blockType: __typename
      ... on Hero
            ${HeroComponent}
      ... on HeroVideo
            ${HeroVideoComponent}
      ... on HeroTextTwoColumn
            ${HeroTextTwoColumnBlock}
      ... on ImageTextBlock
            ${ImageTextBlock}
      ... on ImageTextBlockTwoBlocks
            ${ImageTextBlockTwoBlocks}
      ... on Promotions
            ${PromotionComponent}
      ... on Cta
            ${CTAComponent}
      ... on List
            ${ListComponent}
      ... on BannerBlock
            ${BannerBlock}
      ... on TextCta
            ${TextCtaBlocks}
      ... on Cover
            ${CoverBlock}
      ... on TextImagesGrid {
          blockName
          backgroundColor
          header
          imagesCollection {
            items {
              title
              description
              fileName
              size
              url
              width
              height
            }
          }
        }
      ... on GridComponent {
        blockName
        ${ItemsCollection}
      }
      ... on HeroVideo {
        backgroundColor
          header
          subtitle
          coverPosition
          content {
              json
          }
          videoId
          source
          loopVideoId
          loopVideoSource
          loopVideoFile {
            url
          }
          popupPlayer
          thumbnail: videoThumbnail
            ${CoverComponent}
      }
      ... on FormBlock
        ${FormBlock}
    }
  }
`

export const LPContentBlocksCollection = `
  contentBlocksCollection(limit: 30){
    total
    items {
      blockType: __typename
      ... on Hero
            ${HeroComponent}
      ... on HeroVideo
            ${HeroVideoComponent}
      ... on HeroTextTwoColumn
            ${HeroTextTwoColumnBlock}
      ... on ImageTextBlock
            ${ImageTextBlock}
      ... on PromotionHtmlForm
            ${PromotionHTMLFormComponent}
      ... on TextCta
            ${TextCtaBlocks}
      ... on List
            ${ListComponent}
      ... on ImageTextBlockTwoBlocks
            ${ImageTextBlockTwoBlocks}
      ... on GridComponent
            ${GridComponentBlock}
      ... on TextImagesGrid
            ${TextImagesGridBlock}
      ... on FullVideo
            ${FullVideoBlock}
      ... on LocationBlock
            ${LocationBlock}
      ... on RestaurantsCard
            ${RestaurantsCardsBlock}
      ... on FormBlock
            ${FormBlock}
    }
  }
`
