export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

const dataLayerPushEvent = eventObject => {
  if (window) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(eventObject)
  }
}

export const pageViewEvent = ( pageDetails) =>{
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ pageModel: null, userModel: null });
  window.dataLayer.push({
    event: 'page_view',
    pageModel: {
      'language': pageDetails.language,
      'page_category': pageDetails.pageCategory,
      'page_category2': pageDetails.pageCategory2,
      'page_category3': pageDetails.pageCategory3,
      'page_category4': pageDetails.pageCategory4,
      'page_category5': pageDetails.pageCategory5,
      'page_id': pageDetails.pageId,
      'page_location': pageDetails.pageLocation,
      'page_name': pageDetails.pageName,
      'page_referrer': pageDetails.pageReferrer,
      'page_title': pageDetails.pageTitle,
      'page_type': pageDetails.pageType,
      'site_brand': pageDetails.siteBrand,
      'site_country': pageDetails.siteCountry,
      'site_region': pageDetails.siteRegion,
      'site_section': pageDetails.siteSection,
      'site_section2': pageDetails.siteSection2,
      'site_section3': pageDetails.siteSection3,
      'site_section4': pageDetails.siteSection3,
      'site_section5': pageDetails.siteSection3,
    },
  });
}

// export const pageview  = () => {
export const pageview = url => {
  // only if window and window.dataLayer defined then push function is called
  dataLayerPushEvent({
    event: "pageview",
    page: url,
  })
}

export const TopNavigationClick = url => {
  dataLayerPushEvent({
    event: "keyEvent",
    category: "Page Navigation",
    action: "Top Navigation Click",
    label: url,
  })
}

export const FooterNavigationClick = url => {
  dataLayerPushEvent({
    event: "keyEvent",
    category: "Page Navigation",
    action: "Footer Navigation Click",
    label: url,
  })
}

export const SocialClick = url => {
  dataLayerPushEvent({
    event: "keyEvent",
    category: "Social",
    action: "Social Link Click",
    label: url,
  })
}

export const InternalPromotionImpression = (
  id,
  name,
  creative,
  position = "slot1"
  ) => {
  dataLayerPushEvent({
    event: "internal_promotion_impression",
    ecommerce: {
      promoView: {
        promotions: [
          {
            id, // required, unique ID
            name, // required, human-readable name
            creative,
            position,
          },
        ],
      },
    },
  })
}

export const InternalPromotionClick = (
  id,
  name,
  creative,
  position = "slot1"
) => {
  dataLayerPushEvent({
    event: "promotionClick",
    ecommerce: {
      promoClick: {
        promotions: [
          {
            id, // required, unique ID
            name, // required, human-readable name
            creative,
            position,
          },
        ],
      },
    },
  })
}

// export const InternalPromotionImpression = () => {

// }

// export const InternalPromotionClick = () => {

// }
export const UserLogin = (authenticationMethod,userId,userLoginState) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ userModel: null });  // Clear the previous eventModel object.
  window.dataLayer.push({
    'event': "login",
    'userModel': {
      'authentication_method': authenticationMethod,
      'user_id': userId,
      'user_login_state': userLoginState,
    }
  });
}
export const UserLogout = (userId,userLoginState) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ userModel: null });  // Clear the previous eventModel object.
  window.dataLayer.push({
    'event': "logout",
    'userModel': {
      'user_id': userId,
      'user_login_state': userLoginState,
    }
  });
}

export const SelectPromotion = (items,index) => {
  const itemId = items && items[0] && items[0].item_id ? items[0].item_id : '';
  const itemName = items && items[0] && items[0].trackingName ? items[0].trackingName : '';
  const SelectPromotionItems = [{ item_id: itemId, item_name : itemName }];
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push({
    'event': "select_promotion",
    'ecommerce': {
      'items': SelectPromotionItems,
      'index': index
    }
  });
}

export const PromotionView = (items,index) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push({
    'event': "view_promotion",
    'ecommerce': {
      'items': items,
      'index':index,
      'promotion_id':items && items[0] ? items[0].item_id : '',
      'promotion_name':items && items[0] ? items[0].item_name : ''
    }
  });
}

export const SignUpEvent = (userId,selectCountry) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ eventModel: null });  // Clear the previous eventModel object.
  window.dataLayer.push({
    'event': 'sign_up',
    'userModel': {
      'authentication_method': 'local',
      'user_id': userId,
      'user_login_state': selectCountry,
    }
  });
}
export const Subscribe = (name,method) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ eventModel: null });  // Clear the previous eventModel object.
  window.dataLayer.push({
    'event': 'subscribe',
    'eventModel': {
      'name': name,
      'method': method
    }
  });
}

export const Click = (className,linkText,href,protocol,outbond,linkDomain,linkId) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ eventModel: null });  // Clear the previous eventModel object.
  window.dataLayer.push({
    'event': "click",
    'eventModel': {
    'link_classes': className,
    'link_id': linkId,
    'link_text': linkText,
    'link_url': href,
    'outbound': outbond,
    'link_domain': linkDomain,
    'protocol': protocol,
    }
  });
}
